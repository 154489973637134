<template>
  <transition name="toast">
    <div v-if="visible" class="the-toast fixed bottom-0 left-1/2 transform -translate-x-1/2 px-3
      lg:rounded w-full 2xl:w-1/3 flex justify-between gap-2 items-start"
      :class="toastClasses">

      <p v-html="message" />

      <button @click="visible = false"
        class="text-4xl -mt-2" aria-label="Fermi">×</button>

    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      message: "",
      type: "",
      visible: false,
      timeout: null,
    };
  },

  computed: {
    toastClasses() {
      const ret =  {
        "bg-info": this.type === "info",
        "bg-success": this.type === "success",
        "bg-warning": this.type === "warning",
        "bg-error": this.type === "error",
        "text-white": this.type !== "warning",
        "text-slate-800": this.type === "warning",
      };
      return ret;
    },
  },

  methods: {
    async showToast(msg, type = "info", duration = 3000) {
      // If a toast is already visible, hide it immediately and clear the timeout
      this.visible = false;
      if (this.timeout) {
        clearTimeout(this.timeout);
        await this.$nextTick();
      }

      this.type = type;
      this.message = msg;
      this.visible = true;

      this.timeout = setTimeout(() => {
        this.visible = false;
      }, duration);
    },

    hideAll () {
      this.visible = false;
    }
  },
};
</script>

<style>
.toast-enter-active,
.toast-leave-active {
  transition: transform 0.5s ease
}

.toast-leave-to,
.toast-enter-from {
  transform: translate(-50%, 140px); /* Slightly below the starting position */
}

.the-toast {
  padding-bottom: max(.5rem, calc(env(safe-area-inset-bottom) + 0px));
  padding-top: .5rem;
}

</style>