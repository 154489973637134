<template>
  <input type="text" :value="modelValue" @input="replace($event.target.value)" />
</template>

<script>
export default {
  emits: ["update:modelValue"],
  props: ["modelValue"],

  methods: {
    replace (val) {
      const map = [
        {r:/c[xX]/g, v:"ĉ"},{r:/g[xX]/g, v:"ĝ"},{r:/u[xX]/g, v:"ŭ"},{r:/h[xX]/g, v:"ĥ"},{r:/j[xX]/g, v:"ĵ"},{r:/s[xX]/g, v:"ŝ"},
        {r:/C[xX]/g, v:"Ĉ"},{r:/G[xX]/g, v:"Ĝ"},{r:/U[xX]/g, v:"Ŭ"},{r:/H[xX]/g, v:"Ĥ"},{r:/J[xX]/g, v:"Ĵ"},{r:/S[xX]/g, v:"Ŝ"},
      ];

      let result = val;
      map.forEach(pair => result = result.replace(pair.r, pair.v));
      this.$emit('update:modelValue', result);
    }
  },

  mounted () {
    this.replace(this.modelValue);
  }
}
</script>
