<template>

  <!-- Wrap your dialog in a `TransitionRoot` to add transitions. -->
  <TransitionRoot appear :show="isOpen" as="template">

    <Dialog @close="$emit('close')" class="relative z-50" as="div">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">

        <div class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
      </TransitionChild>

      <div class="fixed inset-0 flex items-center justify-center p-4">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95">

          <DialogPanel class="w-full" :class="wide ? 'max-w-2xl' : 'max-w-xl'">

            <div class="flex justify-between items-center border-b border-gray-300
               modal-hf-bg p-4 rounded-t-xl">
              <DialogTitle class="font-medium">
                <slot name="title">{{title}}</slot>
              </DialogTitle>
              <button class="bg-gray-400 rounded-full h-6 w-6 flex justify-center items-center border-0"
                @click="$emit('close')" aria-label="Fermi">
                <i class="fas fa-close text-white"></i>
              </button>
            </div>

            <!-- <DialogDescription class="p-4 bg-white">
              This will permanently deactivate your account
            </DialogDescription> -->

            <sl-form @submit="submit">

              <div class="bg-white p-4 overflow-y-auto dark:bg-slate-800 content-wrapper">
                <slot></slot>
                <p v-if="error" class="mt-4 bg-red-500 text-white text-base p-2 px-4 rounded-lg">{{error}}</p>
              </div>

              <footer class="border-t border-gray-300 modal-hf-bg rounded-b-xl p-4
                min-h-14"
                v-if="noFooter === undefined || ! noFooter">

                <slot name="footer"></slot>
              </footer>

            </sl-form>

          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>

  </TransitionRoot>
</template>

<script>
export default {
  props: ["isOpen", "title", "no-footer", "wide"],
  emtis: ["close", "submit"],

  data () {
    return {
      error: null,
    }
  },

  methods: {
    submit (form) {
      this.error = null;
      this.$emit('submit', form, this);
    }
  }
}
</script>

<script setup>
  import {
    TransitionRoot, TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    //DialogDescription,
  } from '@headlessui/vue'
</script>

<style scoped>
  .modal-hf-bg {
    background-color: #f5f5f5;
  }

  .dark .modal-hf-bg {
    @apply bg-slate-600;
  }

  .content-wrapper {
    max-height: 75vh;
  }
</style>