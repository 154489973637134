<template>
  <Loading :is-full-page="true" :active="! $appData.loginProcessed"
    :color="$tailwindColors.primary" />

  <div class="container mx-auto relative"
    :class="containerClasses.width">

    <!--px-4 must match the containerClasses px for non game pages -->
    <nav class="py-2 mx-auto fixed z-10 px-4" id="top-nav"
      :class="{'top-nav-android-landscape': ui.isAndroidLandscape}">

      <div class="flex mx-auto w-full items-center justify-between">

        <div class="text-2xl w-1/3 flex gap-5 items-center" v-if="$route.name !== 'home'">

          <!-- <router-link :to="{name: 'home'}" v-if="$route.name !== 'home'">
            <i class="text-white fas fa-home"></i>
          </router-link> -->
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/img/home.png" class="block dark:invert h-8" alt="Hejmen"
              :class="{invert: $route.name === 'game'}" />
          </router-link>

          <a @click="$router.back()">
            <!-- <i class="text-white fas fa-arrow-circle-left"></i> -->
            <img src="@/assets/img/back.png" class="block dark:invert h-8" alt="Reen"
              :class="{invert: $route.name === 'game'}" />
          </a>
        </div>

        <div class="grow text-left">
          <img src="@/assets/7literoj_logo.png" class="h-7 inline-block"
            alt="7 Literoj logotipo" v-if="$route.name === 'home'" />
        </div>

        <!-- notifications -->
        <div> <!-- Do not give arbitrary width to this div, or notif bubles swell badly. -->
          <div v-if="$appData.user" class="flex gap-2 items-center justify-end">
            <router-link :to="{ name: 'games' }" v-if="unseenChatCount > 0" class="sl-notification">
              <i class="fas fa-comments text-white mr-1"></i> {{ unseenChatCount }}
            </router-link>

            <router-link :to="{ name: 'games' }" v-if="unseenGameCount > 0" class="sl-notification">
              <i class="fas fa-play text-white mr-1"></i> {{ unseenGameCount }}
            </router-link>

            <!-- socket connection info -->
            <i class="fas text-sm" v-if="['egeto', 'zubi'].includes($appData?.user?.userName)"
              :class="connected ? 'fa-link text-green-500' : 'fa-link-slash text-red-500'"
            ></i>
          </div>
        </div>
      </div>
    </nav>

    <div class="mx-auto w-full z-0" :class="containerClasses.game" id="content-container">
      <router-view v-if="routerViewVisible && $appData.loginProcessed"
        @handle-orientation="handleOrientation" />

      <!-- <p v-if="! routerViewVisible" class="text-xl text-center mt-16"
        :class="$route.name === 'game' ? 'text-white' : ''">
        Vi devas ensaluti por vidi tiun ĉi {{ $isNativePlatform ? 'ekranon' : 'paĝon' }}.
      </p> -->
    </div>

  </div>

  <!-- store download links -->
  <div v-if="! $isNativePlatform && ['home', 'about'].includes(this.$route.name)"
      class="container mx-auto w-full min-w-full fixed bottom-0 p-4
      flex items-center justify-between"
      :class="containerClasses.width">

    <a href="https://play.google.com/store/apps/details?id=net.bit34.literoj7">
      <img src="@/assets/GetItOnGooglePlay_Badge_Web_color_English.png"
        class="h-12 block" alt="Akiri ĉe Google Play" />
    </a>

    <a href="https://apps.apple.com/us/app/7-literoj/id6742392019">
      <img src="@/assets/download-on-the-app-store.svg"
        class="h-12 block" alt="Akiri ĉe App Store" />
    </a>
  </div>

  <slToast ref="toast" />
</template>

<script>
import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { App } from "@capacitor/app";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";
import slToast from "@/components/sl-toast.vue";
import { StatusBar, Style } from '@capacitor/status-bar';
import { SafeArea } from '@capacitor-community/safe-area';
import { ScreenOrientation } from '@capacitor/screen-orientation';
import { Badge } from '@capawesome/capacitor-badge';
import { Preferences } from '@capacitor/preferences';
//import { AppReview } from "@capawesome/capacitor-app-review";

export default {
  components: { Loading, slToast },

  data () {
    return {
      fcmToken: null,
      connected: false,
      ui: {
        isAndroidLandscape: false,
      }
    };
  },

  provide () {
    return {
      toast: {
        show: this.toast,
        hide: this.hideToast,
      },
    };
  },

  methods: {
    toast(msg, type = "info", duration = 4500) {
      this.$refs.toast.showToast(msg, type, duration);
    },

    hideToast() {
      this.$refs.toast.hideAll();
    },

    async reconnect () {
      try {
        const tokenResult = await FirebaseAuthentication.getIdToken();
        if (tokenResult && tokenResult.token)
          this.$socket.connect(tokenResult.token);
      } catch (err) {
        this.$appData.user = null;
        console.error(err.message); //Should we inform the user?
      } finally {
        this.$appData.loginProcessed = true;
      }
    },

    //we never keep the entire game, we only keep a few properties here,
    //mostly seen, finished etc. This is conformant to the data returned
    //by /user/sync below. Actual game data is refetched every time in its own component.
    //This data is used everywhere except game component.
    setGameMeta (game) {
      const fields = ["id", "finishedDateTime", "player0", "player1", "seen",
        "remainingMinutes", "whoWon", "whoseTurn", "chats",
      ];
      const store = {};
      fields.forEach((f) => (store[f] = game[f]));
      const index = this.$appData.games.findIndex((g) => g.id === game.id);
      if (index === -1) this.$appData.games.push(store);
      else this.$appData.games[index] = store;
    },

    async handleDarkMode () {
      // Check localStorage first, because a lot of users used the version with localStorage.
      // We want to remove localStorage usage in native platforms.
      // We can remove this block after a while. Today is 2025-03-28.
      if (this.$isNativePlatform && localStorage.theme) {
        await Preferences.set({ key: "theme", value: localStorage.theme });
        localStorage.removeItem("theme");
      }

      const storedTheme = (await Preferences.get({ key: "theme" }))?.value;

      if (storedTheme === "dark" ||
          (! storedTheme && window.matchMedia("(prefers-color-scheme: dark)").matches)) {
            this.$appData.darkMode = true;
      } else {
        this.$appData.darkMode = false;
      }
      // We call this from here otherwise Android doesn't update the status bar.
      this.triggerDarkMode(this.$appData.darkMode);
    },

    triggerDarkMode (val) {
      Preferences.set({ key: "theme", value: val ? "dark" : "light" });

      // iOS sometimes doesn't change the background fully until some action forces it to repaint
      // the entire page. We force it by the opacity hack, but only after certain timeout
      // because the transition is smoothened in main.css transition-colors duration-300.
      // It seems this transition is interfering with application of dark mode.
      document.getElementById("app").style.opacity="0.9999";
      setTimeout(() => document.getElementById("app").style.opacity="1", 600);

      document.documentElement.classList.toggle("dark", val);

      if (this.$platform === "android") { // Amazing that iOS doesn't need this. How does it understand?
        StatusBar.setStyle({ style: val ? Style.Dark : Style.Light });
      }
    },

    async handleOrientation () {
      const orientation = await ScreenOrientation.orientation();
      const isLandscape = orientation.type.includes('landscape');
      this.ui.isAndroidLandscape = isLandscape && this.$platform === 'android';
    },

    async handleResize () {
      //#top-nav is fixed, adjust the width equal to the #content-container.
      const width = document.getElementById("content-container").clientWidth;
      document.getElementById("top-nav").style.width = `${width}px`;
    }
  },

  computed: {
    containerClasses () {
      //md doesn't cut on ipad.
      //Players on browser complained that they can't see the whole board on -md, so a custom width.
      const width = this.$isNativePlatform ? "max-w-screen-lg" : "max-w-lg";
      let game = this.$route.name === "game" ? "px-0 py-0 bg-black full-height" : "px-4 pt-2 py-6";
      if (this.ui.isAndroidLandscape) game += " top-nav-android-landscape";
      if (this.$platform === "android") game += " android-content-container";
      return { width, game };
    },

    //Allows non-data pages. Allows data pages only when the user is logged in.
    routerViewVisible () {
      if (["home", "account", "pass-reset", "privacy", "about", "help", "ranks", "news", "pass-reset"]
        .includes(this.$route.name)) return true;
      return !! this.$appData.user;
    },

    unseenChatCount () {
      return this.$appData.games.reduce((acc, game) => {
        return (
          acc +
          game.chats.filter(chat => chat.senderId !== this.$appData.user.id && !chat.seen).length
        );
      }, 0);
    },

    //this is the total of unseen requests and game moves.
    unseenGameCount () {
      const reqs = this.$appData.requests.filter((req) => !req.seen);
      const games = this.$appData.games.filter((game) => !game.seen);
      return reqs.length + games.length;
    },
  },

  watch: {
    "$appData.darkMode" (val) {
      this.triggerDarkMode(val);
    },
  },

  created () {
    if (location.hash.indexOf("#/restarigi-pasvorton") === 0) {
      const uri = location.hash.substr(2);
      this.$router.replace(uri);
      return;
    }

    /*
    This code seems to be working at least on iOS.
    We need to find a time and page to show the review request.
    It may be interesting to query the server for the best timing.
    It may also be meaningful to store the last time we asked for a review in Preferences
    so that we don't ask too frequently. We can't detect this through the server because
    people can create new accounts even though they are the same person.
    setTimeout(async () => {
      if (! this.$isNativePlatform) AppReview.requestReview();
    }, 3000); */

    //we use setTimeout instead of setInterval, because
    //connection failure retriggers end event.
    //TODO: original primus client uses exponential backoff when trying to connect.
    //Find out how to do that and implement.
    //TODO: Sometimes firebase token expires and never connects. Prevent that with
    //a more sophisticated reconnect method.
    this.$socket.on("end", async (e) => {
      this.connected = false;
      setTimeout(() => {
        this.reconnect();
      }, 2000);
    });

    this.$socket.registerNamedCallback("main", (data) => {
      switch (data.message) {
        case "chat/message": {
          //find the game and add chat to it:
          const game = this.$appData.games.find(g => g.id === data.chat.gameId);
          if (game) game.chats.push(data.chat);
          break;
        }
        case "chat/seen": {
          const game = this.$appData.games.find((g) => g.id === data.gameId);
          if (game) game.chats.forEach((c) => (c.seen = 1));
          break;
        }
        case "game-request/update": {
          this.$appData.requests = data.requests;
          break;
        }
        case "game/seen": {
          const game = this.$appData.games.find((g) => g.id === data.gameId);
          if (game) game.seen = 1;
          break;
        }
        case "game/new":
        case "game/playerPassed":
        case "game/playerPlayed":
        case "game/playerSurrendered":
        case "game/finished":
          this.setGameMeta(data.game);
          break;
      }
    });
  },

  async mounted () {
    this.handleResize();
    this.handleDarkMode();
    this.handleOrientation();
    ScreenOrientation.addListener("screenOrientationChange", () => this.handleOrientation());
    window.onresize = () => this.handleResize();

    const resetBadge = async () => {
      if (! this.$isNativePlatform) return; // Web doesn't need it.
      const perms = await Badge.checkPermissions();
      perms.display === "granted" && Badge.set({count: 0});
    };

    this.$socket.on("open", () => {
      this.connected = true;
      resetBadge();

      this.$socket.request({method: "/user/sync", fcmToken: this.fcmToken}, r => {
        ["user", "games", "requests", "unreadNewsCount", "MAX_CHAT_MESSAGES"].forEach((f) => {
          this.$appData[f] = r[f];
        });
        this.$appData.loginProcessed = true;
      });
    });

    //push notifications:
    const tokenCb = token => {
      this.fcmToken = token;
      if (this.fcmToken && this.$socket.isOpen()) {
        //TODO: Either this or sync is probably broken, because the server crashes
        //when this is called. Instead of fcmToken, we are sending "`event` = '[object Object]'"
        this.$socket.send({method: "/user/setMessagingToken", fcmToken: this.fcmToken});
      }
      //if socket is closed, onOpen will call user/sync, which sends the token.
    };

    const actionCb = actionRet => {
      const data = actionRet.event?.notification?.data;
      if (!data || !("uri" in data)) return;
      this.$router.replace(data.uri);
    };

    this.$fcm.init(tokenCb, actionCb);

    await FirebaseAuthentication.removeAllListeners();

    FirebaseAuthentication.addListener("authStateChange", async change => {
      if (!change.user) {
        //logout
        this.$appData.loginProcessed = true;
        this.$appData.user = null;
        this.$socket.close(true);
        //this.$router.replace({name: "home"});
      } else {
        const result = await FirebaseAuthentication.getIdToken();
        this.$socket.connect(result.token);
      }
    });

    //iOS drops the socket on pause, Android keeps.
    //Either way, we drop it for battery time protection.
    App.addListener("pause", async () => {
      if (!this.$isNativePlatform) return; //web doesn't need this treatment and actually breaks it
      if (this.$socket.isOpen()) this.$socket.close(true); //don't restart
    });

    //We need to reconnect the socket for seamless user experience.
    App.addListener("resume", () => {
      if (!this.$isNativePlatform) return; //web doesn't need this treatment and actually breaks it
      if (!this.$socket.isOpen()) this.reconnect();
    });
  },
};
</script>

<style @scoped>
.sl-notification {
  @apply bg-accent rounded-full p-1 px-2 text-sm !text-white min-w-[56px] text-center;
}

#top-nav {
  /* top: calc(env(safe-area-inset-top) - 12px); */
  top: var(--safe-area-inset-top);
  /* @apply mt-1; */
}

.top-nav-android-landscape {
  @apply mt-6;
}

#content-container {
  padding-top: calc(var(--safe-area-inset-top) + 56px);
  height: calc(100vh - var(--safe-area-inset-bottom)); /* iOS and Web */
  @apply overflow-y-auto;
}

/* This is because on devices with 3-button navigation, content stays underneath the buttons
  but this is unusable on the chat screen, because chat input staying at the very bottom.
  TODO: It would be the best if we could detect if Android is using 3-button navigation and
  add this class only in that case.
*/
.android-content-container {
  height: calc(100vh - calc(var(--safe-area-inset-top) - 28px)) !important;
}

.full-height {
  height: 100vh !important;
}

</style>
