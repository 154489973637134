<template>
  <Switch v-model="val"
    @update:modelValue="$emit('update:modelValue', $event)"

    :class="modelValue ? 'bg-primary' : 'bg-slate-400'"
    class="relative inline-flex h-7 w-12 items-center rounded-full"
  >
    <!-- <span class="sr-only">Enable notifications</span> -->
    <span
      :class="modelValue ? 'translate-x-6' : 'translate-x-1'"
      class="inline-block h-5 w-5 transform rounded-full bg-white
        drop-shadow-lg ring-1 transition pointer-events-none"
    />
  </Switch>
</template>

<script>
import { Switch } from '@headlessui/vue'
export default {
  components: { Switch },

  data () {
    return {
      val: this.modelValue,
    }
  },

  emits: ["update:modelValue"],
  props: ["modelValue"],
}
</script>