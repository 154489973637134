import * as Sentry from "@sentry/capacitor";
import * as SentryVue from "@sentry/vue";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/brands.css";
import './assets/main.css';
import auth from "./lib/auth.js";
import FCM from "./lib/fcm.js";
import ajax from "./lib/ajax.js";

import { createApp, reactive } from 'vue';
import App from './App.vue';
import router from './router';

import slButton from "@/components/sl-button.vue";
import slSubmit from "@/components/sl-submit.vue";
import slForm from "@/components/sl-form.vue";
import slModal from "@/components/sl-modal.vue";
import slAlert from "@/components/sl-alert.vue";
import slUser from "@/components/sl-user.vue";
import slXInput from "@/components/sl-x-input.vue";
import slSwitch from "@/components/sl-switch.vue";

import Socket from "./lib/socket";

import { Capacitor } from '@capacitor/core';
import thePackage from "../package.json";

//import { App as CapacitorApp } from '@capacitor/app';
//const appInfo = await CapacitorApp.getInfo(); //Throws error in browser

auth.init();
const app = createApp(App);

Sentry.init({
    app,
    dsn: "https://00ea1fafd93016ff38842a77e8ffb411@o335470.ingest.us.sentry.io/4508491765710848",
    release: "7l-client@" + thePackage.version,
    dist: thePackage.version,
    debug: false,
    enabled: import.meta.env.PROD,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],

    // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
    tracesSampleRate: 0.05,
    tracePropagationTargets: [],

    // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  },
  // Forward the init method from @sentry/vue
  SentryVue.init
);

app.use(router);

app.component("sl-button", slButton);
app.component("sl-submit", slSubmit);
app.component("sl-form", slForm);
app.component("sl-modal", slModal);
app.component("sl-user", slUser);
app.component("sl-x-input", slXInput);
app.component("sl-switch", slSwitch);

app.config.globalProperties.$platform = Capacitor.getPlatform();
app.config.globalProperties.$isNativePlatform = Capacitor.isNativePlatform();

app.config.globalProperties.$appData = reactive({
  loginProcessed: false, //to prevent flash of login dialog while waiting for session to establish.
  darkMode: false,
});

app.config.globalProperties.$fcm = reactive(FCM);

const alertApp = createApp(slAlert)
  .component("sl-button", slButton)
  .mount('#alert-container');

app.config.globalProperties.$alert = options => {
  alertApp.open(options);
};

app.config.globalProperties.$error = content => {
  alertApp.open({content, title: "Eraro", role: "error"});
}

app.config.globalProperties.$success = (content, title = "Sukceso") => {
  alertApp.open({content, title, role: "success"});
}

app.config.globalProperties.$confirm = options => {
  Object.assign(options, {isConfirm: true});
  alertApp.open(options);
};

app.config.globalProperties.$ajax = ajax;
app.config.globalProperties.$socket = new Socket();

app.config.globalProperties.$eoDate = (dateStr, withHr) => {
  const date = dateStr ? new Date (dateStr) : new Date();
  let d = date.getDate();
  let M = date.getMonth() + 1;
  let y = date.getFullYear();
  const months = "Jan,Feb,Mar,Apr,Maj,Jun,Jul,Aŭg,Sep,Okt,Nov,Dec".split(',');
  let ret =  d + " " + months[M-1] + " ";
  //Add year only if different:
  if (new Date().getFullYear() !== y) ret += y + " ";

  if (withHr) {
    let h = date.getHours();
    let m = date.getMinutes();
    if (m < 10) m = "0" + m;
    if (h < 10) h = "0" + h;
    ret += ' ' + h + ':' + m;
  }
  return ret.trim();
}

import tailwindConfig from "../tailwind.config.js";
app.config.globalProperties.$tailwindColors = tailwindConfig.theme.extend.colors;

app.mount('#app');
