<template>
  <div class="page-wrapper">
    <!-- <h1 class="page-header" v-show="false">Bonvenon al 7 Literoj</h1> -->

    <section v-if="$fcm.permissionStatus === 'prompt'">
      <div class="bg-warning rounded-md px-6 py-4 my-4 text-slate-800">
        <div>
          <p class="mb-4 flex gap-2 items-center justify-between">
            <i class="fas fa-bell text-2xl"></i>
            <strong class="text-xl text-center">Permesu sciigojn</strong>
            <i class="fas fa-bell text-2xl"></i>
          </p>
          <div class="mb-4">
            <p>Nia servilo sendos al vi pri ludaj aferoj,
              exemple kiam via kontrauŭlo...
            </p>
            <ul class="list-inside list-disc mt-4">
              <li>ludis</li>
              <li>invitis vin al ludo</li>
              <li>sendis al vi tujmesaĝon</li>
            </ul>
          </div>
          <p class="mb-4">
            7 Literoj estas <strong>malrapida</strong> ludo kaj la plej agrabla sperto estas akirata
            nur kiam la ludanto ricevas tiujn gravajn mesaĝojn kaj reagas al ili.
          </p>
          <p>
            Bonvole alklaku la malsupran butonon kaj permesu vian aparaton montri al vi sciigojn.
          </p>
        </div>

        <div class="text-right mt-4">
          <sl-button class="bg-primary" @click="$fcm.request()">Agordu sciigojn</sl-button>
        </div>

      </div>
    </section>

    <section v-if="$fcm.permissionStatus !== 'prompt' && $appData.loginProcessed">
      <p class="mt-2 text-center text-xl">
        <template v-if="! $appData.user">Vi devas havi konton kaj ensaluti por ludi.</template>
        <template v-if="$appData.user && ! $appData.user.userName">
          Vi bezonas elekti uzantnomon por ludi.
        </template>
        <template v-if="$appData.user?.userName">
          Saluton <b><sl-user :user="$appData.user" link /></b>
        </template>
      </p>

      <nav class="mt-16">
        <ul class="text-center text-xl menu-ul">
          <li v-if="$appData.user?.userName">
            <strong>
              <router-link class="button-link" :to="{name: 'games'}">
                 Ludi
              </router-link>
            </strong>
          </li>
          <li v-else>
            <strong>
              <router-link class="button-link" :to="{name: 'account'}">
                {{$appData.user ? 'Elektu uzantnomon' : 'Ensalutu aŭ registriĝu'}}
              </router-link>
            </strong>
          </li>
          <li>&nbsp;</li>
          <li>
            <router-link class="button-link flex gap-2 items-center justify-center" :to="{name: 'news'}">
              Diskonigoj
              <span v-if="unreadNewsCount"
                class="bg-primary rounded-full p-1.5 min-w-7 text-white text-xs
                  flex items-center justify-center">
                {{unreadNewsCount}}
            </span>
            </router-link>
          </li>
          <li>
            <router-link class="button-link" :to="{name: 'ranks'}">Rangoj</router-link>
          </li>
          <li>
            <router-link class="button-link" v-if="$appData.user?.userName"
              :to="{name: 'account'}">Konto</router-link>
          </li>
          <li>
            <router-link class="button-link" :to="{name: 'help'}">Helpo</router-link>
          </li>
          <li>
            <router-link :to="{name: 'about'}">Pri</router-link>
          </li>

        </ul>
      </nav>

    </section>
  </div>
</template>

<script>
export default {
  name: 'Home',
  computed: {
    unreadNewsCount () {
      return this.$appData?.unreadNewsCount || 0;
    }
  }
}
</script>

