<template>

  <span class="inline-flex gap-3 items-center" :class="invert ? 'flex-row-reverse' : ''">
    <template v-if="pic">
      <img :src="user.picture" class="relative rounded-full sl-user-image" v-if="user.picture && ! broken"
        :alt="'Profilbildo de ' + user.userName" @error="err" :class="classes" />

      <span v-else :class="classes"
        class="rounded-full border border-slate-500 inline-flex items-center
        justify-center bg-slate-200">
        <i class="fas fa-user-large text-lg text-slate-600"></i>
      </span>
    </template>

    <router-link v-if="link && user.userName" :to="{name: 'user', params: {userId: id}}">
      {{user.userName}}
    </router-link>
    <span v-else>
      <template v-if="user.userName">
        {{user.userName}}
      </template>
      <i v-else class="text-base">foriĝintulo</i>
    </span>
  </span>

</template>

<script>
  export default {
    props: {
      user: Object,   //must have id, picture, userName properties
      big: Boolean,   //large picture
      pic: Boolean,   //Show pic when present
      link: Boolean,  //Show link when present
      invert: Boolean, //Image on right when true
    },

    data () {
      return {
        broken: false,
      }
    },

    computed: {
      classes () {
        return this.big ? 'w-12 h-12' : 'w-8 h-8';
      },

      id () {
        return this.user.id || this.user.userId;
      }
    },

    methods: {
      err () {
        this.broken = true;
      }
    }
  }
</script>
