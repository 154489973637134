import { Capacitor, CapacitorHttp } from '@capacitor/core';

export default {
  async get (url) {
    if (Capacitor.isNativePlatform()) {
      //Prepend host if not present.
      url.substr(0, 4) === "http" || (url = import.meta.env.VITE_HTTP_HOST + url);
      const ret = await CapacitorHttp.get({url}); //From native layer, bypasses CORS.
      return ret.data; //https://medium.com/enappd/how-to-make-api-calls-in-ionic-capacitor-apps-a9c22d205a1d
    } else {
      //We don't add host here, as it's already present in the vite.config.js proxy.
      //On production, we make a request to the same host without a specified domain.
      const ret = await fetch(url);
      return await ret.json();
    }
  }
}