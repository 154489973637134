import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),

  routes: [
    { path: '/', name: 'home', component: Home },
    { path: '/helpo', name: 'help', component: () => import("@/views/Help.vue") },
    { path: '/pri', name: 'about', component: () => import("@/views/About.vue") },
    { path: '/rangoj', name: 'ranks', component: () => import("@/views/Ranks.vue") },
    { path: '/ludanto/:userId', name: 'user', component: () => import("@/views/User.vue") },
    { path: '/konto', name: 'account', component: () => import("@/views/Account.vue") },
    { path: '/diskonigoj/:id?', name: 'news', component: () => import("@/views/News.vue") },
    { path: '/privateco', name: 'privacy', component: () => import("@/views/Privacy.vue") },
    { path: '/ludoj', name: 'games', component: () => import("@/views/Games.vue") },
    { path: '/nova', name: 'new-game', component: () => import("@/views/NewGame.vue") },
    { path: '/restarigi-pasvorton', name: 'pass-reset', component: () => import("@/views/ResetPass.vue") },

    { path: '/ludo/:gameId', name: 'game', component: () => import("@/views/game/Game.vue") },
    { path: '/babilo/:gameId', name: 'chat', component: () => import("@/views/Chat.vue") },
  ],

  scrollBehavior(to, from, savedPosition) {
    //return savedPosition || { top: 0 };
    return { top: 0 };
  },
});

// This is ultra important as it fixes the issue of the page disappearing
// on iOS when navigating from a page that has been momentum-scrolling.
// We figured this by logging document.getElementById('home-main').getBoundingClientRect().top
// on home page and after navigating to it from a scrolling page the value was in negatives.
// This hack was suggested by claude.ai https://claude.ai/chat/8b0c8fdb-eb13-456b-9ae5-70f4feff9f4a
// We still don't understand exactly what is happening.
// TODO: If we land on a page with long content, it keeps scrolling seamlessly.
// We also need to find a way to prevent this but whatever we tried didn't work.
router.afterEach(() => {
  requestAnimationFrame(() => {
    const wrapper = document.querySelector(".page-wrapper");
    if (wrapper) {
      wrapper.style.position = 'relative';
      //scroll to top
      //wrapper.scrollTo(0, -48);

    }
  });
});

export default router;
