<template>
  <form @submit.prevent.stop="onSubmit">
    <slot></slot>
    <p v-if="error" class="mt-4 bg-red-500 text-white text-base p-2 px-4 rounded-md">
      {{error}}
    </p>
  </form>
</template>

<script>
 export default {
  emits: ["submit"],

  data () {
    return {
      state: 'dormant', //other is loading
      error: null,
    }
  },

  methods: {
    onSubmit () {
      if (this.state === "loading") return;
      this.error = null;
      this.state = "loading";
      this.$emit('submit', this);
    },
  },
}
</script>