import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
//import FCMWorker from './firebase-messaging-sw.js?worker';
const vapidKey = "BLJDdAYwWLjekVI9YiP-0lrHuTz5ium_rMsXqT67yov7uAPxSzjZ8l4_GFxVdbVBwGFAUxHH6CUl_cA2hga0gYk";

export default {
  onTokenCb: null,
  permissionStatus: null,

  async processWithPermissions (onTokenCb) {
    //console.log("FCM: process with permissions called..");
    if (! Capacitor.isNativePlatform()) {
      try {
        await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      } catch (e) {
        //TODO: send it to Sentry
      }
    }
    const opts = Capacitor.isNativePlatform() ? {} : {vapidKey};
    const ret = await FirebaseMessaging.getToken(opts);
    // The following line is probably unnecessary, because we already have a listener for this
    // which calls the onTokenCb. It seems it always runs regardless of the permission status.
    // Server has multiple logs for the same token, so it seems it is called multiple times.
    // That means we will be sending the token in user/sync even if the user has not granted permission.
    // But we still keep it  here because we can't be sure if that's the case on Android, iOS and web.
    onTokenCb(ret?.token || null);
  },

  async init (onTokenCb, onActionCb) {
    this.onTokenCb = onTokenCb;

    await FirebaseMessaging.removeAllListeners();

    await FirebaseMessaging.addListener('tokenReceived', event => {
      //console.log('FCM: tokenReceived',  event );
      onTokenCb(event?.token || null);
    });

    await FirebaseMessaging.addListener('notificationReceived', event => {
      //Nothing to do, because we don't process notifs within the app.
      //console.log('notificationReceived', { event });
    });

    await FirebaseMessaging.addListener('notificationActionPerformed', event => {
      //TODO: We need perform a redirect to the relevant page.
      //console.log('notificationActionPerformed', { event });
      onActionCb({event});
    });

    //check permissions, request if not granted.
    //console.log("FCM: checking permissions");
    const permissions = await FirebaseMessaging.checkPermissions();
    this.permissionStatus = permissions.receive;
    if (permissions.receive === 'granted') {
      //console.log("permission ALREADY granted");
      await this.processWithPermissions(onTokenCb);
    } else {
      //console.log("FCM: permission not already granted: ", this.permissionStatus);
    }
  },

  async request () {
    try {
      //console.log("FCM: Requesting permission");
      const permissionResult = await FirebaseMessaging.requestPermissions();
      //console.log("FCM: permission request returned", permissionResult);
      this.permissionStatus = permissionResult.receive;
      //console.log("permission is: ", this.permissionStatus);
      if (permissionResult.receive === 'granted') {
        //console.log("FCM: permission granted for the first time.");
        await this.processWithPermissions(this.onTokenCb);
      }
    } catch (error) {
      console.error("FCM: Permission request failed with error:", error);
    }
  },

  //Clears all notifictions and the badge. Essentially for iOS.
  async clear () {
    FirebaseMessaging.removeAllDeliveredNotifications();
    Badge.clear();
  }

}