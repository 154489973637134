<template>

  <!-- Wrap your dialog in a `TransitionRoot` to add transitions. -->
  <TransitionRoot appear :show="opts.isOpen" as="template">

    <Dialog @close="close(false)" class="relative z-50" as="div">
      <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0" enter-to="opacity-100"
        leave="duration-200 ease-in" leave-from="opacity-100" leave-to="opacity-0">

        <div class="fixed inset-0 bg-black bg-opacity-25" aria-hidden="true" />
      </TransitionChild>

      <div class="fixed inset-0 flex items-center justify-center p-4">
        <TransitionChild as="template" enter="duration-300 ease-out" enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100" leave="duration-200 ease-in" leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95">

          <DialogPanel class="w-full max-w-md">

            <div class="flex justify-between items-center border-b border-gray-300 modal-hf-bg p-4 rounded-t-xl">
              <DialogTitle class="font-medium">
                <slot name="title">{{opts.title}}</slot>
              </DialogTitle>
              <button class="bg-gray-400 rounded-full h-6 w-6 flex justify-center items-center border-0"
                @click="close(false)" aria-label="Fermi">
                <i class="fas fa-close text-white"></i>
              </button>
            </div>

            <div class="p-4 bg-white dark:bg-slate-800 flex gap-4">
              <div class="fa-3x">
                <i class="fas" :class="[styles.iconColor, styles.icon]"></i>
              </div>
              <slot>
                <div v-html="opts.content"></div>
              </slot>
            </div>

            <footer class="p-4 modal-hf-bg border-t border-gray-300 rounded-b-xl flex justify-end gap-2">
              <sl-button :light="! opts.isConfirm ? styles.light : ''"
                @click="close(false)"
                :class="! opts.isConfirm ? styles.button : ''">Fermi</sl-button>

              <sl-button v-if="opts.isConfirm" :light="styles.light"
                @click="close(true)" :class="styles.button" >{{opts.confirmLabel}}</sl-button>
            </footer>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>

  </TransitionRoot>
</template>


<script setup>
  import {
    TransitionRoot, TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
    //DialogDescription,
  } from '@headlessui/vue';

  import { computed, reactive } from 'vue';

  const def = {
    isOpen: false,
    title: "",
    content: "",
    role: "default",
    isConfirm: false,
    onClose: null,
    confirmLabel: "Bone",
  };

  const init = JSON.parse(JSON.stringify(def));

  const opts = reactive(init);

  const open = options => {
    if (! options) options = {};
    Object.keys(def).forEach(k => {
      opts[k] = k in options ? options[k] : def[k];
    });
    opts.isOpen = true;
  }

  const close = result => {
    opts.isOpen = false;
    if (opts.onClose) opts.onClose(result);
  }

  const styles = computed(() => {
    return {
      warning: {button: "bg-orange-400", iconColor: "text-orange-400", icon: "fa-warning", light: false},
      error: {button: "bg-red-600", iconColor: "text-red-600", icon: "fa-circle-exclamation", light: false},
      success: {button: "bg-green-600", iconColor: "text-green-600", icon: "fa-circle-check", light: false},
      default: {button: "", iconColor: "", icon: null, light: true},
    }[opts.role || 'default'];
  });


  defineExpose({
    open,
  });
</script>


<style scoped>
  .modal-hf-bg {
    background-color: #f5f5f5;
  }

  .dark .modal-hf-bg {
    @apply bg-slate-600;
  }
</style>