<template>

  <button type="button"
    class="p-1 px-4 text-center rounded-full"
    :class="! light ? 'text-white' : ''"
    :disabled="loading">

    <slot v-if="! loading"></slot>
    <i v-else :class="! light ? 'text-white' : ''" class="fas fa-spinner fa-spin fa-xl"></i>
  </button>

</template>

<script>
  export default {
    props: {
      light: Boolean,
      loading: Boolean,
    }
  }

</script>