import { Capacitor } from '@capacitor/core';
import { initializeApp } from 'firebase/app';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import firebaseConfig from '../firebase-config.js';

let auth;

//most of them are listed at:
//https://firebase.google.com/docs/reference/js/firebase.auth.Auth.html?authuser=0#signinwithpopup
//https://firebase.google.com/docs/reference/js/firebase.auth.Error?authuser=0
const errorTranslations = {
  "auth/email-already-in-use": "La retpoŝtadreso jam estas registrita.",
  "auth/invalid-email": "Retpoŝtadreso ne validas.",
  "auth/credential-already-in-use": "Tiu konto apartenas al aliulo.", //we saw this when connecting facebook to our other email.
  "auth/account-exists-with-different-credential": "Konto kun tiu retpoŝtadreso jam registriĝis per alia metodo. Se vi volas konekti kontojn, bonvole unue ensalutu kun la alia metodo kaj tiam konekti kontojn ĉe la konta ekrano.",
  "auth/popup-closed-by-user": "", //"Ensaluta fenestro fermiĝis." User closed it, we don't show anything.
  "auth/user-cancelled": "", //user refused to connect with social media. We don't display anything
  "auth/cancelled-popup-request": "Nova fenestro malfermiĝis, la malnova estas nuligita.",
  //auth/operation-not-allowed
  "auth/weak-password": "Pasvorto malfortas. Almenaŭ 8 signoj estas bezonataj.",
  "auth/user-not-found": "Uzanto ne troviĝas.",
  "auth/wrong-password": "Pasvorto malĝustas.",
  "auth/expired-action-code": "Forpasis la permesita tempo. Bonvole reprovu restarigi vian pasvorton.",
  "auth/invalid-action-code": "La ligilo estas malformita aŭ jam estis uzita. Bonvole reprovu restarigi vian pasvorton.",
  "auth/invalid-user-token": "Eraro okazis. Vi devas reensaluti.",
  "auth/network-request-failed": "Retkonekta eraro okazis.",
  "auth/requires-recent-login": "Freŝa ensaluto estas bezonata. Bonvolu elsaluti kaj reensaluti, kaj tiam provi denove.",
  "auth/user-token-expired": "Eraro okazis. Vi bezonas reensaluti.",
  "auth/web-storage-unsupported": "Via retumilo ne subtenas reta konservejo aŭ vi malŝaltis ĝin.",
  "auth/too-many-requests": "Konto estas portempe malŝaltita pro tro multe da provoj. Vi povas tuj restarigi ĝin restarigante vian pasvorton aŭ vi povas atendi iom kaj provi poste.",

  //added by ege to show something on password change when the user is not logged in:
  "auth/not-logged-in": "Ne estas ensalutita uzanto.",
}

export default {
  init () {
    if (! Capacitor.isNativePlatform()) {
      const firebaseApp = initializeApp(firebaseConfig);
      auth = getAuth(firebaseApp);
    }
  },

  getErrorTranslation (errCode) {
    //on web code comes in "auth/..." format, but on iOS and Android it comes naked.
    //so we add the "auth/" prefix when it is not present:
    let code = errCode;
    if (errCode.indexOf('auth/') !== 0) code = `auth/${errCode}`;
    return code in errorTranslations ? errorTranslations[code] : "Nekonata eraro okazis.";
  },

  //For the following 2 functions:
  //auth is guaranteed to exist because this page is only visible in the browser.
  async checkPasswordResetCode (code) {
    return await verifyPasswordResetCode(auth, code);
  },

  async resetPassword (code, newPassword) {
    await confirmPasswordReset(auth, code, newPassword);
  }
}


