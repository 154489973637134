<template>

  <sl-button type="submit"
    :disabled="$parent.state === 'loading'">

    <slot v-if="$parent.state !== 'loading'"></slot>
    <i v-else :class="! light ? 'text-white' : ''" class="fas fa-spinner fa-spin fa-xl"></i>
  </sl-button>

</template>

<script>
  export default {
    props: {
      light: Boolean,
    }
  }

</script>