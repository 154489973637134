/** @type {import('tailwindcss').Config} */
export default {
  content: ["./index.html", "./src/**/*.{vue,js,ts,jsx,tsx}"],
  darkMode: 'class', // Enables class-based dark mode
  theme: {
    extend: {
      fontFamily: {
        ubuntu: ["Ubuntu", "sans-serif"],
      },
      colors: {
        //primary: "#00d1b2",
        primary: "#f13477",
        accent: "#3273dc",
        //success: "#23d160",
        success: "#48c78e",
        danger: "#f14668",
        error: "#f14668",
        info: "#3e8ed0",
        link: "#485fc7",
        warning: "#ffe08a",
      },
    },
  },
  plugins: [],
};
