const PING_TIMEOUT = 3000;
const PING_INTERVAL = 20000;

export default class Socket {

  constructor () {
    this.requestStack = {}; //keys are unique numbers, values are callback functions.
    this.callbacks = {};
    this.namedDataCallbacks = {};
    this.socket = null;
    this.pingTimeout = null;
    this.pingInterval = null;
    this.autoReconnect = true;
    this.alertOnScreen = false;
  }

  connect (idToken) {
    if (! idToken) throw new Error("idToken is required");
    if (this.socket && this.socket.readyState === 1) {
      this.autoReconnect = false;
      this.socket.close();
    }

    //console.log("SOCKET HOSTUMUZ:", import.meta.env.VITE_SOCKET_HOST)
    const uri = import.meta.env.VITE_SOCKET_HOST + "/primus?idToken=" + idToken;
    try {
      this.socket = new WebSocket(uri);
    } catch (err) {
      console.error("DISASTER. Socket failed. Investigate what's going on: ", err.message);
    }

    this._prepareSocket();
  }

  on (eventName, cb) {
    if (! ["open", "end", "error", "data"].includes(eventName))
      throw new Error("Bad event name for Socket.on: '" + eventName + "'");
    if (! this.callbacks[eventName]) this.callbacks[eventName] = [];
    //console.log("SOCKET ON: " + eventName);
    this.callbacks[eventName].push(cb);
    return this;
  }

  off (eventName, cb) {
    if (! this.callbacks[eventName]) return;
    const index = this.callbacks[eventName].indexOf(cb);
    if (index > -1) this.callbacks[eventName].splice(index, 1);
  }

  registerNamedCallback (eventName, cb) {
    this.namedDataCallbacks[eventName] = cb;
  }

  close (dontRestart) {
    if (dontRestart) this.autoReconnect = false;
    if (this.socket) this.socket.close();
  }

  isOpen () {
    return this.socket && this.socket.readyState === 1;
  }

  send (data) {
    let alert;

    if (this.socket.readyState !== 1) {
      if (! this.alertOnScreen) {
        //TODO: Use a better way to show this alert, mobiles don't like it.
        console.error("Konekto mankas.", data);

        /* alert = Notification.open({
          position: 'is-top',
          message: "Konekto mankas",
          indefinite: true,
          queue: false,
          hasIcon: true,
          type: 'is-warning',
        });
        alert.$on('close', () => this.alertOnScreen = false); */
      }
      this.alertOnScreen = true;
      return false;
    }

    this.socket.send(JSON.stringify(data));
    return true;
  }

  request (data, cb) {
    data.requestId = Math.random();
    this.requestStack[data.requestId] = cb;
    return this.send(data);
  }

  /****************************************************************************/
  /****************************************************************************/
  /****************************************************************************/

  //private
  _prepareSocket () {
    this.socket.onopen = e  => {
      //console.log("SOCKET OPENED!");
      this.autoReconnect = true;
      this.pingInterval = setInterval(() => {
        this.send('7literoj::ping::' + (new Date()).getTime());
        this.pingTimeout = setTimeout(() => {
          //connection dropped.
          this.close();
        }, PING_TIMEOUT);
      }, PING_INTERVAL);

      if (this.callbacks.open) this.callbacks.open.forEach(cb => cb());
    }

    this.socket.onmessage = e => {
      //console.log("SOCKET ONMESSAGE: " + e.data);
      if (e.data.includes('7literoj::pong::')) {
        return clearTimeout(this.pingTimeout);
      }
      //Handle primus server ping-pong and close events first
      if (e.data.includes('primus::ping')) {
        const pong = e.data.replace("ping", "pong");
        return this.socket.send(pong);
      } else if (e.data.includes('primus::server::close')) {
        return;
      } else { //regular messages that we parse.
        const message = JSON.parse(e.data);
        if (message.requestId) {
          const cb = this.requestStack[message.requestId];
          if (cb) {
            delete this.requestStack[message.reqId];
            return cb(message);
          }
        }

        if (this.callbacks.data) this.callbacks.data.forEach(cb => cb(message));
        Object.values(this.namedDataCallbacks).forEach(fn => fn(message));
      }
    }

    this.socket.onclose = e => {
      //console.log("SOCKET CLOSED: ", e.message);
      clearInterval(this.pingInterval);
      clearTimeout(this.pingTimeout);
      if (! this.autoReconnect) return;
      if (this.callbacks.end) this.callbacks.end.forEach(cb => cb(e));
    }

    this.socket.onerror = e => {
      //console.log("SOCKET ERROR: ", e.message);
      if (this.callbacks.error) this.callbacks.error.forEach(cb => cb(e.message));
    }
  }
}
